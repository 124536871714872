'use client';

import { ChoiceGroup, Typography } from '@little-worker/hestia';
import { useTranslations } from 'next-intl';
import React, { useCallback, useState } from 'react';

import { FlowType } from '@/types/common';
import useRouterWithTracking from '@hooks/useRouterWithTracking';

import FormElementWrapper from '../../pages-elements/FormElementWrapper';
import FormNavigationButtonsGroup from '../../pages-elements/FormNavigationButtonsGroup';

function Landing() {
  const t = useTranslations('Landing');
  const { push } = useRouterWithTracking();
  const [flowType, setFlowType] = useState<`${FlowType}` | null>(null);

  const onNext = useCallback(() => {
    if (flowType === FlowType.Faconne) {
      push('/faconne');
    } else if (flowType === FlowType.Trouve) {
      push('/trouve');
    }
  }, [flowType]);

  const navigationButtons = (
    <FormNavigationButtonsGroup isFirstStep isNextDisabled={!flowType} onNext={onNext} />
  );

  return (
    <FormElementWrapper navigationButtons={navigationButtons}>
      <Typography className="mb-150" element="h1" size="extra-large" fontStyle="bold">
        {t('title')}
      </Typography>

      <Typography className="mb-500" element="p" size="extra-large">
        {t('page_description')}
      </Typography>

      <ChoiceGroup
        choiceGroupContainerClassName="grid grid-cols-4 !gap-grid_xs sm:!gap-grid xl:grid-cols-8"
        type="radio"
        choiceGroupStyle="outlined"
        choiceGroupOptionSize="large"
        value={flowType as (string & string[]) | undefined}
        onChange={(value) => setFlowType(value as `${FlowType}`)}
        aria-label={t('title')}
      >
        <ChoiceGroup.Item
          className="col-span-2"
          value={FlowType.Trouve}
          iconName="House02"
          data-testid="choice-trouve"
        >
          {t('choices.0.label')}
        </ChoiceGroup.Item>
        <ChoiceGroup.Item
          className="col-span-2"
          value={FlowType.Faconne}
          iconName="Ruler"
          data-testid="choice-faconne"
        >
          {t('choices.1.label')}
        </ChoiceGroup.Item>
      </ChoiceGroup>
    </FormElementWrapper>
  );
}

export default Landing;
